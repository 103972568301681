
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../mixins/titleMixin";
import { postLoginRequest } from "../apis/requests/api";
import { saveToken, saveAuthority, saveUserName } from "@/apis/ajax";

@Options({
  components: {},
  mixins: [titleMixin],
})
export default class SignIn extends Vue {
  userName!: string;
  password!: string;
  loginIn() {
    postLoginRequest(this.userName, this.password).then((result) => {
      if (result.code === 200) {
        saveToken(result.data.api_token as string);
        // store.dispatch("Authority/updateState", result.data.authority);
        saveAuthority(result.data.authority as string);
        // store.dispatch("UserName/updateState", result.data.name);
        saveUserName(result.data.realname as string);
        router.push({ path: "/common/home" });
      } else {
        alert(result.msg);
      }
    });
  }

  mounted() {
    window.addEventListener("keydown", this.keyDown);
  }

  keyDown(e: any) {
    //如果是按回车则执行登录方法
    if (e.keyCode == 13) {
      this.loginIn();
    }
  }

  unmounted() {
    window.removeEventListener("keydown", this.keyDown, false);
  }
}
