<template>
  <div class="login-page-box">
    <div class="login-left">
      <img src="@/assets/img/signIn-bg.png" alt="" class="login-left-img" />
    </div>
    <div class="login-right">
      <div class="right-box">
        <img src="@/assets/img/login-blue.png" alt="" class="login-img" />
        <div class="login-title">面试后台管理系统</div>
        <div class="login-input-box">
          <span class="iconfont icon-account"></span>
          <input
            type="text"
            placeholder="邮箱/会员名/8位ID"
            class="login-input"
            v-model="userName"
          />
        </div>
        <div class="login-input-box">
          <span class="iconfont icon-mima"></span>
          <input
            type="password"
            placeholder="请输入登录密码"
            class="login-input"
            v-model="password"
          />
        </div>
        <el-button type="primary" @click="loginIn()">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { titleMixin } from "../mixins/titleMixin";
import { postLoginRequest } from "../apis/requests/api";
import { saveToken, saveAuthority, saveUserName } from "@/apis/ajax";

@Options({
  components: {},
  mixins: [titleMixin],
})
export default class SignIn extends Vue {
  userName!: string;
  password!: string;
  loginIn() {
    postLoginRequest(this.userName, this.password).then((result) => {
      if (result.code === 200) {
        saveToken(result.data.api_token as string);
        // store.dispatch("Authority/updateState", result.data.authority);
        saveAuthority(result.data.authority as string);
        // store.dispatch("UserName/updateState", result.data.name);
        saveUserName(result.data.realname as string);
        router.push({ path: "/common/home" });
      } else {
        alert(result.msg);
      }
    });
  }

  mounted() {
    window.addEventListener("keydown", this.keyDown);
  }

  keyDown(e: any) {
    //如果是按回车则执行登录方法
    if (e.keyCode == 13) {
      this.loginIn();
    }
  }

  unmounted() {
    window.removeEventListener("keydown", this.keyDown, false);
  }
}
</script>

<style scoped>
.login-page-box {
  width: 70%;
  padding: calc(60 / 14 * 1rem) 0;
  background: #ffffff;
  box-shadow: 0px 0px 50px 0px #ebebeb;
  border-radius: calc(30 / 14 * 1rem);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
.login-left,
.login-right {
  width: 50%;
}
.login-left {
  display: flex;
  justify-items: center;
  justify-content: flex-end;
}
.login-left-img {
  width: 80%;
}
.right-box {
  width: 80%;
  margin: 0 auto;
}
.login-img {
  width: 20%;
  margin: 0 auto;
  display: block;
}
.login-title {
  font-size: calc(28 / 14 * 1rem);
  font-family: MicrosoftYaHei;
  color: #333333;
  margin-bottom: calc(40 / 14 * 1rem);
  margin-top: calc(10 / 14 * 1rem);
  text-align: center;
  font-weight: 300;
}
.login-input-box {
  display: flex;
  align-items: center;
  height: calc(40 / 14 * 1rem);
  width: 75%;
  border-bottom: 1px solid #d8d8d8;
  margin: calc(20 / 14 * 1rem) auto 0;
}
.icon-account,
.icon-mima {
  font-size: calc(16 / 14 * 1rem);
}
.login-input {
  border: 0;
  width: 100%;
  height: 100%;
  background: none;
  padding-left: 15px;
  outline: 0;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
  font-size: calc(14 / 14 * 1rem);
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
  font-size: calc(14 / 14 * 1rem);
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  font-size: calc(14 / 14 * 1rem);
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
  font-size: calc(14 / 14 * 1rem);
}
::v-deep() .el-button {
  width: 75%;
  margin: calc(30 / 14 * 1rem) auto 0;
  display: block;
}
</style>
