export const POST_LOGIN = "/login"; // 登录

export const POST_LOGOUT = "/logout"; // 退出

export const POST_INDEX = "/index"; // 首页看板

export const POST_LISTS_ADMIN = "/lists-admin"; // 管理员管理

export const POST_ADD_ADMIN = "/add-admin"; // 管理员管理-增加管理员

export const POST_SHOW_ADMIN = "/show-admin"; // 管理员管理-管理员详细信息

export const POST_DEL_ADMIN = "/del-admin"; // 管理员管理-删除管理员

export const POST_EDIT_ADMIN = "/edit-admin"; // 管理员管理-编辑管理员

export const GET_LISTS_STAFF = "/administrations"; // 行政人员管理

export const POST_ADD_EDIT_STAFF = "/administrations/edit"; // 行政人员-新增or编辑

export const POST_DEL_STAFF = "/administrations/delete"; // 行政人员-删除

export const GET_LISTS_EVALUATION = "/opinions"; // 面试评价详情列表

export const POST_LISTS_RESUME = "/lists-resume"; // 简历管理

export const POST_SHOW_RESUME = "/show-resume"; // 简历管理-简历详情

export const GET_DEPART_SHOW_PHOTO = "/depart_show_photo"; // 面试速记

export const POST_TEST_RESUME = "/test-resume"; // 简历管理-测试详情

export const POST_DEL_RESUME = "/del-resume"; // 简历管理-删除简历

export const POST_LISTS_PROPAGANDA = "/lists-propaganda"; // 宣传管理

export const POST_ADD_PROPAGANDA = "/add-propaganda"; // 宣传管理-增加宣传内容

export const POST_SHOW_PROPAGANDA = "/show-propaganda"; // 宣传管理-宣传内容详情

export const POST_EDIT_PROPAGANDA = "/edit-propaganda"; // 宣传管理-修改宣传详情

export const POST_DEL_PROPAGANDA = "/del-propaganda"; // 宣传管理-删除宣传详情

export const POST_LISTS_JOB = "/lists-job"; // 岗位管理

export const POST_ADD_JOB = "/add-job"; // 岗位管理-删除岗位

export const POST_SHOW_JOB = "/show-job"; // 岗位管理-岗位详情

export const POST_EDIT_JOB = "/edit-job"; // 岗位管理-编辑岗位

export const POST_DEL_JOB = "/del-job"; // 岗位管理-编辑岗位

export const POST_LIST_CAT = "/lists-cat"; // 题型管理

export const POST_ADD_CAT = "/add-cat"; // 题型管理-增加题型

export const POST_SHOW_CAT = "/show-cat"; // 题型管理-题型详情

export const POST_EDIT_CAT = "/edit-cat"; // 题型管理-编辑题型

export const POST_DEL_CAT = "/del-cat"; // 题型管理-删除题型

export const POST_LISTS_EXAM = "/lists-exam"; // 题目管理

export const POST_SHOW_EXAM = "/show-exam"; // 题目管理-题目详情

export const POST_ADD_EXAM = "/add-exam"; // 题目管理-增加题目

export const POST_EDIT_EXAM = "/edit-exam"; // 题目管理-编辑题目

export const POST_DEL_EXAM = "/del-exam"; // 题目管理-删除题目

export const POST_LISTS_LEVEL = "/lists-level"; // 得分等级管理

export const POST_ADD_LEVEL = "/add-level"; // 得分等级管理-增加得分等级

export const POST_SHOW_LEVEL = "/show-level"; // 得分等级管理-得分等级详情

export const POST_EDIT_LEVEL = "/edit-level"; // 得分等级管理-编辑得分等级

export const POST_DEL_LEVEL = "/del-level"; // 得分等级管理-删除得分等级

export const POST_LISTS_CATEGORY = "/lists-category"; // 岗位分类管理

export const POST_ADD_CATEGORY = "/add-category";

export const POST_SHOW_CATEGORY = "/show-category";

export const POST_DEL_CATEGORY = "/del-category";

export const POST_EDIT_CATEGORY = "/edit-category";

export const POST_LISTS_SPECIAL = "/lists-special"; // 特别题库管理

export const POST_ADD_SPECIAL = "/add-special";

export const POST_SHOW_SPECIAL = "/show-special";

export const POST_EDIT_SPECIAL = "/edit-special";

export const POST_DEL_SPECIAL = "/del-special";

export const POST_STATUS_RESUME = "/status-resume";

export const POST_BOARDING_RESUME = "/boarding-resume";

export const POST_CONFIRM_RESUME = "/confirm-resume";

export const POST_LISTS_FEEDBACK = "/lists-feedback"; // 面试反馈

export const POST_ADD_FEEDBACK = "/add-feedback";

export const POST_SHOW_FEEDBACK = "/show-feedback";

export const POST_DEL_FEEDBACK = "/del-feedback";

export const POST_EDIT_FEEDBACK = "/edit-feedback";

export const POST_GROUP_RESUME = "/group-resume";

export const POST_JOB_RESUME = "/job-resume";

export const GET_STATUS_RESUME = "/get-status-resume"; // 获取简历所有状态

export const POST_INDUCTION = "/induction"; // 入职

export const POST_REFUSE_INDUCTION = "/refuse_induction"; // 未到岗

export const GET_EXPAND_LISTS = "/expansions"; // 拓展题库列表

export const POST_ADD_EDIT_EXPAND = "/expansions/edit"; // 新增or修改拓展题

export const POST_DELETE_EXPAND = "/expansions/delete"; // 删除拓展题