import { ICat, ICategory, ISpecial, IExam, IHomeIndex, IJob, IPaging, IPropaganda, IResume, ITestDetails, IUser, IStaffUser, Level, IBoardingResume, IComeOnData, IFeedback, IGroup, IBoardingResumeJob, IexpandQuestion } from "@/typing";
import { ajaxPost, getToken, ajaxGet } from "../ajax";
import {
  POST_LOGIN,
  POST_LOGOUT,
  POST_INDEX,
  POST_LISTS_ADMIN,
  POST_ADD_ADMIN,
  POST_SHOW_ADMIN,
  POST_DEL_ADMIN,
  POST_EDIT_ADMIN,
  POST_LISTS_RESUME,
  POST_SHOW_RESUME,
  POST_TEST_RESUME,
  POST_DEL_RESUME,
  POST_LISTS_PROPAGANDA,
  POST_ADD_PROPAGANDA,
  POST_SHOW_PROPAGANDA,
  POST_EDIT_PROPAGANDA,
  POST_DEL_PROPAGANDA,
  POST_LISTS_JOB,
  POST_ADD_JOB,
  POST_SHOW_JOB,
  POST_EDIT_JOB,
  POST_DEL_JOB,
  POST_LIST_CAT,
  POST_ADD_CAT,
  POST_SHOW_CAT,
  POST_EDIT_CAT,
  POST_DEL_CAT,
  POST_LISTS_EXAM,
  POST_ADD_EXAM,
  POST_SHOW_EXAM,
  POST_EDIT_EXAM,
  POST_DEL_EXAM,
  POST_LISTS_LEVEL,
  POST_ADD_LEVEL,
  POST_SHOW_LEVEL,
  POST_EDIT_LEVEL,
  POST_DEL_LEVEL,
  POST_LISTS_CATEGORY,
  POST_ADD_CATEGORY,
  POST_SHOW_CATEGORY,
  POST_DEL_CATEGORY,
  POST_EDIT_CATEGORY,
  POST_LISTS_SPECIAL,
  POST_ADD_SPECIAL,
  POST_SHOW_SPECIAL,
  POST_EDIT_SPECIAL,
  POST_DEL_SPECIAL,
  POST_STATUS_RESUME,
  POST_BOARDING_RESUME,
  POST_CONFIRM_RESUME,
  POST_LISTS_FEEDBACK,
  POST_ADD_FEEDBACK,
  POST_SHOW_FEEDBACK,
  POST_DEL_FEEDBACK,
  POST_GROUP_RESUME,
  POST_JOB_RESUME,
  POST_EDIT_FEEDBACK,
  GET_STATUS_RESUME,
  POST_INDUCTION,
  POST_REFUSE_INDUCTION,
  GET_DEPART_SHOW_PHOTO,
  GET_LISTS_STAFF,
  POST_ADD_EDIT_STAFF,
  POST_DEL_STAFF,
  GET_LISTS_EVALUATION,
  GET_EXPAND_LISTS,
  POST_ADD_EDIT_EXPAND,
  POST_DELETE_EXPAND
} from "../url";

/**
 * 登录
 * @param name 
 * @param password 
 * @returns 
 */
const postLoginRequest = (name: string, password: string) => {
  return ajaxPost<IUser>(POST_LOGIN, {
    name,
    password
  })
}

/**
 * 退出
 * @returns 
 */
const postLogoutRequest = () => {
  const token = getToken();
  return ajaxPost<string>(POST_LOGOUT, { 'api_token': token });
}

/**
 * 首页看板
 * @returns 
 */
const postIndexRequest = () => {
  const token = getToken();
  return ajaxGet<IHomeIndex>(POST_INDEX, { 'api_token': token });
}

/**
 * 管理员列表
 * @param name 
 * @param realname 
 * @param password 
 * @param authority 
 * @returns 
 */
const postListsAdminRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<IUser>>(POST_LISTS_ADMIN, {
    'api_token': token,
    limit,
    page
  });
}

/**
 * 创建管理员
 * @param user 
 * @returns 
 */
const postAddAdminRequest = (user: IUser) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_ADMIN, {
    'api_token': token,
    name: user.name,
    realname: user.realname,
    password: user.password,
    authority: user.authority,
    type: user.type,
    department: user.department,
    mobile: user.mobile,
    reard_all_resume: user.reard_all_resume
  });
}

/**
 * 管理员详情
 * @param id 
 * @returns 
 */
const postShowAdminRequest = (id: number) => {
  const token = getToken();
  return ajaxGet<IUser>(POST_SHOW_ADMIN, { 'api_token': token, id });
}

/**
 * 删除管理员
 * @param id 
 * @returns 
 */
const postDelAdminRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_ADMIN, { 'api_token': token, id });
}

/**
 * 编辑管理员
 * @param user 
 * @returns 
 */
const postEditAdminRequest = (user: IUser) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_ADMIN, {
    'api_token': token,
    id: user.id,
    name: user.name,
    password: user.password,
    authority: user.authority,
    realname: user.realname,
    type: user.type,
    department: user.department,
    mobile: user.mobile,
    reard_all_resume: user.reard_all_resume
  });
}

/**
 * 行政人员列表
 * @param limit 
 * @param page 
 * @returns 
 */
const getListsStaffRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<IUser>>(GET_LISTS_STAFF, {
    'api_token': token,
    limit,
    page
  });
}


/**
 * 行政人员新增or编辑
 * @param user 
 * @returns 
 */
const postAddEditStaffRequest = (user: IStaffUser) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_EDIT_STAFF, {
    'api_token': token,
    name: user.name,
    phone: user.phone,
    id: user.id ? user.id : ''
  });
}

/**
 * 删除行政人员
 * @param id 
 * @returns 
 */
const postDelStaffRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_STAFF, { 'api_token': token, id });
}

/**
 * 面试评价列表
 * @param limit 
 * @param page 
 * @returns 
 */
const getListsEvaluationRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<IUser>>(GET_LISTS_EVALUATION, {
    'api_token': token,
    limit,
    page
  });
}

/**
 * 简历管理
 * @returns 
 */
const postListsResumeRequest = (limit = 10, page = 1, name?: string, jid?: number, source?: string, status?: string, createTime?: string) => {
  const token = getToken();
  return ajaxGet<IPaging<IResume>>(POST_LISTS_RESUME, { 'api_token': token, limit, page, name, jid, source, status, create_time: createTime });
}

const postShowResumeRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<{ data: IResume, answers_list: any[] }>(POST_SHOW_RESUME, { 'api_token': token, id });
}

/**
 * 面试速记
 * @param interviewId 
 * @returns 
 */
const getDepartShowPhoto = (interviewId: number) => {
  const token = getToken();
  return ajaxPost<{ photo: string, record_id: number }[]>(GET_DEPART_SHOW_PHOTO, { "api_token": token, "interview_id": interviewId, })
}

// const postTestResumeRequest = (tempToken: string) => {
//     const token = getToken();
//     return ajaxPost<{ cat: { name: string }, data: ITestDetails[], score: number, total_score: number }>(POST_TEST_RESUME, { 'api_token': token, id: tempToken });
// }

const postTestResumeRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<{ question_type: string, data: ITestDetails[], score: number, total_score: number }>(POST_TEST_RESUME, { 'api_token': token, id });
}

const postDelResumeRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_RESUME, { 'api_token': token, id });
}

const postListsPropagandaRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<IPropaganda>>(POST_LISTS_PROPAGANDA, { 'api_token': token, limit, page });
}

const postAddPropagandaRequest = (propaganda: IPropaganda) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_PROPAGANDA,
    {
      'api_token': token,
      theme: propaganda.theme,
      sort: propaganda.sort,
      content: propaganda.content,
      thumbnail: propaganda.thumbnail,
      fileList: propaganda.fileList
    });
}

const postShowPropagandaRequest = (id: number) => {
  const token = getToken();
  return ajaxGet<string>(POST_SHOW_PROPAGANDA, { 'api_token': token, id });
}

const postEditPropagandaRequest = (propaganda: IPropaganda) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_PROPAGANDA, {
    'api_token': token,
    id: propaganda.id,
    theme: propaganda.theme,
    sort: propaganda.sort,
    content: propaganda.content,
    thumbnail: propaganda.thumbnail,
    fileList: propaganda.fileList
  });
}

const postDelPropagandaRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_PROPAGANDA, {
    'api_token': token,
    id
  });
}

const postListsJobRequest = (limit = 10, page = 1, cid?: number, job_id?: number) => {
  const token = getToken();
  return ajaxGet<IPaging<IJob>>(POST_LISTS_JOB, { 'api_token': token, limit, page, cid, job_id });
}

const postAddJobRequest = (job: IJob) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_JOB, {
    'api_token': token,
    'job_name': job.job_name,
    sort: job.sort,
    cid: job.cid,
    number: job.number
  });
}

const postShowJobRequest = (id: number) => {
  const token = getToken();
  return ajaxGet<IJob>(POST_SHOW_JOB, {
    'api_token': token,
    id
  });
}

const postEditJobRequest = (job: IJob) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_JOB, {
    'api_token': token,
    id: job.id,
    'job_name': job.job_name,
    sort: job.sort,
    cid: job.cid,
    number: job.number
  });
}

const postDelJobRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_JOB, {
    'api_token': token,
    id
  });
}

const postListCatRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<ICat>>(POST_LIST_CAT, { 'api_token': token, limit, page });
}

const postAddCatRequest = (cat: ICat) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_CAT, {
    'api_token': token,
    name: cat.name,
    sort: cat.sort
  });
}

const postShowCatRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_SHOW_CAT, {
    'api_token': token,
    id
  });
}

const postEditCatRequest = (cat: ICat) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_CAT, {
    'api_token': token,
    name: cat.name,
    sort: cat.sort,
    id: cat.id
  });
}

const postDelCatRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_CAT, {
    'api_token': token,
    id
  });
}

const postListsExamRequest = (limit = 10, page = 1, cid?: number, question?: string) => {
  const token = getToken();
  return ajaxGet<IPaging<IExam>>(POST_LISTS_EXAM, { 'api_token': token, limit, page, cid, question });
}

const postAddExamRequest = (exam: IExam) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_EXAM, {
    'api_token': token,
    cid: exam.cid,
    question: exam.question,
    answer: exam.answer,
    option1: exam.option1,
    option2: exam.option2,
    option3: exam.option3,
    option4: exam.option4,
    analysis: exam.analysis,
    fileList: exam.fileList
  });
}

const postShowExamRequest = (id: number) => {
  const token = getToken();
  return ajaxGet<IExam>(POST_SHOW_EXAM, {
    'api_token': token,
    id
  });
}

const postEditExamRequest = (exam: IExam) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_EXAM, {
    'api_token': token,
    id: exam.id,
    cid: exam.cid,
    question: exam.question,
    answer: exam.answer,
    option1: exam.option1,
    option2: exam.option2,
    option3: exam.option3,
    option4: exam.option4,
    analysis: exam.analysis,
    fileList: exam.fileList
  });
}

const postDelExamRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_EXAM, {
    'api_token': token,
    id
  });
}

const postListsLevelRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<Level>>(POST_LISTS_LEVEL, { 'api_token': token, limit, page });
}

const postAddLevelRequest = (level: Level) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_LEVEL, {
    'api_token': token,
    'score_low': level.score_low,
    'score_high': level.score_high,
    'honorary_name': level.honorary_name
  });
}

const postShowLevelRequest = (id: number) => {
  const token = getToken();
  return ajaxGet<Level>(POST_SHOW_LEVEL, {
    'api_token': token,
    id
  });
}

const postEditLevelRequest = (level: Level) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_LEVEL, {
    'api_token': token,
    'score_low': level.score_low,
    'score_high': level.score_high,
    'honorary_name': level.honorary_name,
    id: level.id
  });
}

const postDelLevelRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_LEVEL, {
    'api_token': token,
    id
  });
}

const postListsCategoryRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<ICategory>>(POST_LISTS_CATEGORY, { 'api_token': token, limit, page });
}

const postAddCategoryRequest = (category: ICategory) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_CATEGORY, {
    'api_token': token,
    'cat': category.cat,
    'sort': category.sort
  });
}

const postShowCategoryRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_SHOW_CATEGORY, {
    'api_token': token,
    id
  });
}

const postDelCategoryRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_CATEGORY, {
    'api_token': token,
    id
  });
}

const postEditCategoryRequest = (category: ICategory) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_CATEGORY, {
    'api_token': token,
    'id': category.id,
    'cat': category.cat,
    'sort': category.sort
  });
}

const postListsSpecialRequest = (limit = 10, page = 1, cat?: string, question?: string) => {
  const token = getToken();
  return ajaxGet<IPaging<ISpecial>>(POST_LISTS_SPECIAL, { 'api_token': token, limit, page, cat, question });
}

const postAddSpecialRequest = (special: ISpecial) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_SPECIAL, {
    'api_token': token,
    'cat': special.cat,
    'content': JSON.stringify(special.content),
    'question': special.question,
    'fileList': JSON.stringify(special.fileList),
    'name': special.name
  });
}

const postShowSpecialRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_SHOW_SPECIAL, {
    'api_token': token,
    id
  });
}

const postEditSpecialRequest = (special: ISpecial) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_SPECIAL, {
    'api_token': token,
    'id': special.id,
    'cat': special.cat,
    'content': JSON.stringify(special.content),
    'question': special.question,
    'fileList': JSON.stringify(special.fileList),
    'name': special.name
  });
}

const postDelSpecialRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_SPECIAL, {
    'api_token': token,
    id
  });
}

const postStatusResumeRequest = (id: number, status: string) => {
  const token = getToken();
  return ajaxPost<string>(POST_STATUS_RESUME, {
    'api_token': token,
    id,
    status
  });
}

const postBoardingResumeRequest = () => {
  const token = getToken();
  return ajaxPost<IBoardingResume>(POST_BOARDING_RESUME, {
    'api_token': token
  });
}

const postConfirmResumeRequest = (comeOnData: IComeOnData, status: number) => {
  const token = getToken();
  return ajaxPost<IBoardingResume>(POST_CONFIRM_RESUME, {
    'api_token': token,
    id: comeOnData.id,
    'oa_dep_group': comeOnData.oa_dep_group || 0,
    'oa_department': comeOnData.oa_department,
    'oa_job': comeOnData.oa_job,
    'oa_user_company': comeOnData.oa_user_company,
    'oa_address': comeOnData.oa_address,
    did: comeOnData.did,
    idCard: comeOnData.idCard,
    birthStatus: comeOnData.birthStatus,
    email: comeOnData.email,
    nickname: comeOnData.nickname,
    status
  });
}

/**
 * 拒绝入职
 * @returns 
 */
const postNoInduction = (id: number, notOnDutyReason: string) => {
  const token = getToken();
  return ajaxPost<IBoardingResume>(POST_CONFIRM_RESUME, {
    'api_token': token,
    id,
    status: 51,
    'pass_job_reason': notOnDutyReason
  });
}

/**
 * 入职
 * @param id 
 * @param inductionTime 
 */
const postInduction = (id: number, inductionTime: string) => {
  const token = getToken();
  return ajaxPost<string>(POST_INDUCTION, {
    'api_token': token,
    id,
    'induction_time': inductionTime
  });
}

/**
 * 未到岗
 * @param id 
 * @returns 
 */
const postRefuseInduction = (id: number, pass_job_reason: string) => {
  const token = getToken();
  return ajaxPost<string>(POST_REFUSE_INDUCTION, {
    'api_token': token,
    id,
    'pass_job_reason': pass_job_reason
  });
}

const postListsFeedbackRequest = (rid: number) => {
  const token = getToken();
  return ajaxPost<IFeedback[]>(POST_LISTS_FEEDBACK, {
    'api_token': token,
    rid
  });
}

const postAddFeedbackRequest = (feedback: IFeedback) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_FEEDBACK, {
    'api_token': token,
    rid: feedback.rid,
    name: feedback.name,
    'create_time': feedback.create_time,
    merit: feedback.merit,
    shortcoming: feedback.shortcoming
  });
}

const postShowFeedbackRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<IFeedback>(POST_SHOW_FEEDBACK, {
    'api_token': token,
    id
  });
}

const postDelFeedbackRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DEL_FEEDBACK, {
    'api_token': token,
    id
  });
}

const postEditFeedbackRequest = (feedback: IFeedback) => {
  const token = getToken();
  return ajaxPost<string>(POST_EDIT_FEEDBACK, {
    'api_token': token,
    name: feedback.name,
    create_time: feedback.create_time,
    merit: feedback.merit,
    shortcoming: feedback.shortcoming,
    id: feedback.id
  });
}

const getStatusResume = () => {
  const token = getToken()
  return ajaxGet<any>(GET_STATUS_RESUME, {
    'api_token': token,
  });
}

/**
 * 拓展题列表
 * @param limit 
 * @param page 
 * @returns 
 */
const getListsExpandRequest = (limit = 10, page = 1) => {
  const token = getToken();
  return ajaxGet<IPaging<IUser>>(GET_EXPAND_LISTS, {
    'api_token': token,
    limit,
    page
  });
}

/**
 * 新增or修改拓展题
 * @param expand 
 * @returns 
 */
const postAddExpandRequest = (expand: IexpandQuestion) => {
  const token = getToken();
  return ajaxPost<string>(POST_ADD_EDIT_EXPAND, {
    'api_token': token,
    'id': expand.id,
    'title': expand.expandQuestion,
    'reference_answer': expand.expandAnswers
  });
}


/**
 * 删除拓展题
 * @param id 
 * @returns 
 */
const postDeleteExpandRequest = (id: number) => {
  const token = getToken();
  return ajaxPost<string>(POST_DELETE_EXPAND, {
    'api_token': token,
    id
  });
}
export {
  postLoginRequest,
  postLogoutRequest,
  postIndexRequest,
  postListsAdminRequest,
  postAddAdminRequest,
  postShowAdminRequest,
  postDelAdminRequest,
  postEditAdminRequest,
  postListsResumeRequest,
  postShowResumeRequest,
  postTestResumeRequest,
  postDelResumeRequest,
  postListsPropagandaRequest,
  postAddPropagandaRequest,
  postShowPropagandaRequest,
  postEditPropagandaRequest,
  postDelPropagandaRequest,
  postListsJobRequest,
  postAddJobRequest,
  postShowJobRequest,
  postEditJobRequest,
  postDelJobRequest,
  postListCatRequest,
  postAddCatRequest,
  postShowCatRequest,
  postEditCatRequest,
  postDelCatRequest,
  postListsExamRequest,
  postAddExamRequest,
  postShowExamRequest,
  postEditExamRequest,
  postDelExamRequest,
  postListsLevelRequest,
  postAddLevelRequest,
  postShowLevelRequest,
  postEditLevelRequest,
  postDelLevelRequest,
  postListsCategoryRequest,
  postAddCategoryRequest,
  postShowCategoryRequest,
  postDelCategoryRequest,
  postEditCategoryRequest,
  postListsSpecialRequest,
  postAddSpecialRequest,
  postShowSpecialRequest,
  postEditSpecialRequest,
  postDelSpecialRequest,
  postStatusResumeRequest,
  postBoardingResumeRequest,
  postConfirmResumeRequest,
  postListsFeedbackRequest,
  postAddFeedbackRequest,
  postShowFeedbackRequest,
  postDelFeedbackRequest,
  postEditFeedbackRequest,
  getStatusResume,
  postNoInduction,
  postInduction,
  postRefuseInduction,
  getDepartShowPhoto,
  getListsStaffRequest,
  postAddEditStaffRequest,
  postDelStaffRequest,
  getListsEvaluationRequest,
  getListsExpandRequest,
  postAddExpandRequest,
  postDeleteExpandRequest
}
