<template>
  <div id="nav"></div>
  <div class="page">
    <router-view />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class App extends Vue {
  mounted() {
    const designW = 1440; //设计稿宽
    const fontRate = 12;

    setTimeout(() => {
      //适配
      document.getElementsByTagName("html")[0].style.fontSize =
        (document.body.offsetWidth / designW) * fontRate + "px";
      document.getElementsByTagName("body")[0].style.fontSize =
        (document.body.offsetWidth / designW) * fontRate + "px";
    }, 100);

    //监测窗口大小变化
    window.addEventListener(
      "onorientationchange" in window ? "orientationchange" : "resize",
      function () {
        document.getElementsByTagName("html")[0].style.fontSize =
          (document.body.offsetWidth / designW) * fontRate + "px";
        document.getElementsByTagName("body")[0].style.fontSize =
          (document.body.offsetWidth / designW) * fontRate + "px";
      },
      false
    );
  }
}
</script>
<style>
.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

.el-button {
  height: calc(40 / 14 * 1rem);
  box-shadow: 0px 4px 18px 0px rgba(79, 129, 249, 0.34) !important;
  border-radius: 8px !important;
  border: 0;
}
.el-button--primary {
  background-color: #4f81f9 !important;
  border-color: #4f81f9 !important;
}
html,
body,
#app {
  height: 100%;
}
.page {
  height: 100%;
  width: 100%;
}
/* css 初始化 */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
  color: #555;
  font-size: 14px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
td,
th,
caption {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
a {
  color: #555;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
img {
  border: none;
}
ol,
ul,
li {
  list-style: none;
}
input,
textarea,
select,
button {
  font: 14px Verdana, Helvetica, Arial, sans-serif;
}
table {
  border-collapse: collapse;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  *zoom: 1;
}
.right-content-box {
  padding: calc(29 / 14 * 1rem);
}
.paging {
  margin-top: calc(30 / 14 * 1rem);
  text-align: center;
}
.el-breadcrumb {
  font-size: calc(12 / 14 * 1rem) !important;
}
.manage-box {
  margin-top: calc(20 / 14 * 1rem);
  border-radius: calc(8 / 14 * 1rem);
}
.form-box {
  padding: 20px;
}
.el-drawer__header {
  border-bottom: 1px solid #eee !important;
  padding: 15px 20px !important;
  margin-bottom: 0 !important;
}
.el-checkbox-group {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-checkbox.is-bordered.el-checkbox--mini {
  height: 40px !important;
}
.el-checkbox {
  width: 46% !important;
  text-align: center !important;
  margin-right: 0 !important;
  margin-bottom: 20px !important;
  color: #999 !important;
}
.el-checkbox__input {
  display: none !important;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0 !important;
}
.el-checkbox__label {
  padding-left: 0 !important;
}
.el-checkbox.is-bordered {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}
.el-pagination button:disabled {
  background: none !important;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: none !important;
}
.el-table tr {
  padding-left: calc(20 / 14 * 1rem) !important;
  padding-right: calc(20 / 14 * 1rem) !important;
}
.el-pager li {
  background: none !important;
  font-weight: 200 !important;
}
.el-button {
  box-shadow: unset !important;
  padding: calc(4 / 14 * 1rem) calc(15 / 14 * 1rem) !important;
}
.add-box {
  text-align: right;
}
.el-drawer__body{
  overflow-y: auto;
}
.el-menu{
  width: 270px;
}

</style>
