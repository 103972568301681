
<template>
  <div class="page-box">
    <!-- 侧边导航栏 -->
    <div class="page-left">
      <div class="top-img">
        <input class="focus" type="checkbox" id="focus" />
        <img src="@/assets/img/logo-white.png" alt="" class="logo-img" />
      </div>
      <el-menu
        :default-active="getMenuActive() || '1'"
        class="el-menu-vertical-demo"
      >
        <el-menu-item
          v-if="state.authorityList.includes('首页')"
          index="1"
          @click="goHome()"
        >
          <span class="iconfont icon-home"></span>
          <template #title>首页</template>
        </el-menu-item>
        <el-submenu index="2" v-if="state.authorityList.includes('管理员管理')">
          <template #title>
            <span class="iconfont icon-admin"></span>
            <span>管理员管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="2-1" @click="goManagers()">
              管理员列表
            </el-menu-item>
            <el-menu-item index="2-2" @click="goStaff()">行政人员</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3" v-if="state.authorityList.includes('简历管理')">
          <template #title>
            <span class="iconfont icon-resume"></span>
            <span>简历管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="3-1" @click="goResumeList()"
              >简历列表</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="4" v-if="state.authorityList.includes('宣传管理')">
          <template #title>
            <span class="iconfont icon-xuanchuan"></span>
            <span>宣传管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="4-1" @click="goPublicityList()"
              >宣传列表</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="5" v-if="state.authorityList.includes('岗位管理')">
          <template #title>
            <span class="iconfont icon-post"></span>
            <span>岗位管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="5-1" @click="goPostManagement()"
              >岗位列表</el-menu-item
            >
            <el-menu-item index="5-2" @click="goJobClassification()"
              >岗位分类</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6" v-if="state.authorityList.includes('题库管理')">
          <template #title>
            <span class="iconfont icon-tiku"></span>
            <span>题库管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="6-1" @click="goQuestionList()"
              >题型列表</el-menu-item
            >
            <el-menu-item index="6-2" @click="goQuestionBankList()"
              >题库列表</el-menu-item
            >
            <!-- <el-menu-item index="6-3" @click="goScoreLevel()"
              >得分等级</el-menu-item
            > -->
            <el-menu-item index="6-4" @click="goSpecialQuestionBank()"
              >特别题库</el-menu-item
            >
            <el-menu-item index="6-5" @click="goExpandQuestionBank()"
              >拓展题库</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="7" v-if="state.authorityList.includes('面试评价')">
          <template #title>
            <span class="iconfont icon-resume"></span>
            <span>面试评价</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="7-1" @click="goEvaluationList()"
              >评价列表</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>

    <!-- 右边内容 -->
    <div class="page-right">
      <!-- 顶部操作栏 -->
      <div class="right-bar">
        <span>{{ state.userName }}</span>
        <div class="head-img" v-for="fit in fits" :key="fit">
          <el-image
            style="
              width: calc(40 / 14 * 1rem);
              height: calc(40 / 14 * 1rem);
              border-radius: 50%;
            "
            :src="url"
            :fit="fit"
          ></el-image>
        </div>
        <span class="iconfont icon-tuichu" @click="dialogVisible = true"></span>
      </div>

      <!-- 面包屑导航 -->
      <el-breadcrumb
        class="breadcrumb-height"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item :to="{ path: '/common/home' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ getCrumbs() }}</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 右边内容box -->
      <div class="right-box">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component
              :is="Component"
              v-if="$route.meta.keepAlive"
              :key="$route.name"
            />
          </keep-alive>
          <component :is="Component" v-if="!$route.meta.keepAlive" />
        </router-view>
      </div>
    </div>
    <el-dialog
      title="提示"
      v-model="dialogVisible"
      width="30%"
    >
      <!-- :before-close="handleClose" -->
      <span>确认退出吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="
              dialogVisible = false;
              Logout();
            "
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { postLogoutRequest } from "../../apis/requests/api";
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import { mapGetters } from "vuex";
import {
  getAuthority,
  getUserName,
  removeAuthority,
  removeToken,
  removeUserName,
} from "../../apis/ajax";
import { ElMessage } from "element-plus";
import { reactive } from "@vue/reactivity";

@Options({
  components: {},
  props: ["authority"],
  computed: {
    ...mapGetters("Crumbs", ["getCrumbs"]),
    ...mapGetters("MenuActive", ["getMenuActive"]),
  },
})
export default class Common extends Vue {
  dialogVisible = false;
  fits = ["fill"];
  url =
    "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg";

  state = reactive<{ userName: string; authorityList: string[] }>({
    authorityList: [],
    userName: "",
  });

  created() {
    this.state.authorityList = (getAuthority() as string).split("/");
    this.state.userName = getUserName() as string;
  }

  goHome() {
    router.push({ path: "/common/home" });
  }

  goManagers() {
    router.push({ path: "/common/manage" });
  }

  goStaff() {
    router.push({ path: "/common/staff" });
  }

  goResumeList() {
    router.push({ path: "/common/resume-list" });
  }

  goPublicityList() {
    router.push({ path: "/common/publicity-list" });
  }

  goPostManagement() {
    router.push({ path: "/common/post-management" });
  }

  goJobClassification() {
    router.push({ path: "/common/job-classification" });
  }

  goQuestionList() {
    router.push({ path: "/common/question-list" });
  }

  goQuestionBankList() {
    router.push({ path: "/common/question-bank-list" });
  }

  goScoreLevel() {
    router.push({ path: "/common/score-level" });
  }

  goSpecialQuestionBank() {
    router.push({ path: "/common/special-question-bank" });
  }

  goExpandQuestionBank() {
    router.push({ path: "/common/expand-question-bank" });
  }

  goEvaluationList() {
    router.push({ path: "/common/interview-evaluation" });
  }

  Logout() {
    postLogoutRequest().then((_) => {
      ElMessage.success({
        message: "退出成功！",
        type: "success",
      });
      removeToken();
      removeAuthority();
      removeUserName();
      router.push({ path: "/" });
      window.location.reload();
    });
  }
}
</script>

<style scoped>
.page-box {
  height: 100% !important;
  width: 100% !important;
  background: #f0f3f8 !important;
  display: flex;
}
.page-left {
  background: linear-gradient(180deg, #53a8fa 0%, #4f81f9 100%);
  height: 100%;
  width: 270px;
  overflow: auto;
}

.page-left::-webkit-scrollbar {
    display: none;
}
.page-right {
  flex: 1;
}
.top-img {
  display: flex;
  justify-content: center;
  padding: calc(30 / 14 * 1rem) calc(20 / 14 * 1rem);
}
::v-deep().logo-img {
  width: calc(90 / 14 * 1rem) !important;
}
.page-right {
  flex: 1;
}
::v-deep().el-menu {
  background-color: transparent !important;
  border-right: 0;
}
::v-deep().el-radio-group {
  position: absolute;
  right: calc(-80 / 14 * 1rem);
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
::v-deep().el-submenu__title {
  color: rgba(255, 255, 255, 0.6) !important;
}
::v-deep().el-menu-item {
  color: rgba(255, 255, 255, 0.6) !important;
}
::v-deep().el-submenu__title i {
  color: rgba(255, 255, 255, 0.6) !important;
}
::v-deep() .el-menu-item i {
  color: rgba(255, 255, 255, 0.6) !important;
}
::v-deep().el-menu-item.is-active {
  background: rgba(255, 255, 255, 0.2);
  color: #fff !important;
}
::v-deep().el-menu-item-group__title {
  color: rgba(255, 255, 255, 0.6) !important;
  padding: 0 !important;
}
::v-deep().el-submenu .el-menu-item {
  padding-left: 45px !important;
  font-size: 12px !important;
}
::v-deep().el-submenu__title:hover {
  background: rgba(255, 255, 255, 0.2) !important;
}
::v-deep().el-menu-item:focus,
.el-menu-item:hover {
  background: rgba(255, 255, 255, 0.2) !important;
}
::v-deep().iconfont {
  margin-right: calc(10 / 14 * 1rem);
}
.right-bar {
  width: 100%;
  background: #fff;
  height: calc(60 / 14 * 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep().head-img {
  margin: 0 calc(15 / 14 * 1rem) 0 calc(10 / 14 * 1rem);
}
.icon-tuichu {
  margin-right: calc(30 / 14 * 1rem);
}
.right-box {
  overflow-y: auto;
  height: calc(100% - calc(120 / 14 * 1rem));
}
.right-box::-webkit-scrollbar {
  display: none !important;
  width: 0;
}
.breadcrumb-height {
  height: calc(60 / 14 * 1rem);
  display: flex;
  align-items: center;
  padding-left: calc(30 / 14 * 1rem);
}

.focus {
  position: fixed;
  left: 200vw;
}
</style>
