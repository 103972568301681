
import { postLogoutRequest } from "../../apis/requests/api";
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import { mapGetters } from "vuex";
import {
  getAuthority,
  getUserName,
  removeAuthority,
  removeToken,
  removeUserName,
} from "../../apis/ajax";
import { ElMessage } from "element-plus";
import { reactive } from "@vue/reactivity";

@Options({
  components: {},
  props: ["authority"],
  computed: {
    ...mapGetters("Crumbs", ["getCrumbs"]),
    ...mapGetters("MenuActive", ["getMenuActive"]),
  },
})
export default class Common extends Vue {
  dialogVisible = false;
  fits = ["fill"];
  url =
    "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg";

  state = reactive<{ userName: string; authorityList: string[] }>({
    authorityList: [],
    userName: "",
  });

  created() {
    this.state.authorityList = (getAuthority() as string).split("/");
    this.state.userName = getUserName() as string;
  }

  goHome() {
    router.push({ path: "/common/home" });
  }

  goManagers() {
    router.push({ path: "/common/manage" });
  }

  goStaff() {
    router.push({ path: "/common/staff" });
  }

  goResumeList() {
    router.push({ path: "/common/resume-list" });
  }

  goPublicityList() {
    router.push({ path: "/common/publicity-list" });
  }

  goPostManagement() {
    router.push({ path: "/common/post-management" });
  }

  goJobClassification() {
    router.push({ path: "/common/job-classification" });
  }

  goQuestionList() {
    router.push({ path: "/common/question-list" });
  }

  goQuestionBankList() {
    router.push({ path: "/common/question-bank-list" });
  }

  goScoreLevel() {
    router.push({ path: "/common/score-level" });
  }

  goSpecialQuestionBank() {
    router.push({ path: "/common/special-question-bank" });
  }

  goExpandQuestionBank() {
    router.push({ path: "/common/expand-question-bank" });
  }

  goEvaluationList() {
    router.push({ path: "/common/interview-evaluation" });
  }

  Logout() {
    postLogoutRequest().then((_) => {
      ElMessage.success({
        message: "退出成功！",
        type: "success",
      });
      removeToken();
      removeAuthority();
      removeUserName();
      router.push({ path: "/" });
      window.location.reload();
    });
  }
}
