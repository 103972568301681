interface menuActiveState {
    menuActive: string;
}

const state: menuActiveState = {
    menuActive: '1'
}

const getters = {
    getMenuActive: (state: menuActiveState) => () => {
        return state.menuActive;
    }
}

const actions = {
    updateState(context: any, payload: string) {
        context.commit('updateState', payload);
    }
}

const mutations = {
    updateState(state: menuActiveState, payload: string) {
        state.menuActive = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}