import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import SignIn from '../views/sign-in.vue';
import Common from '../views/common/index.vue';
import { getToken } from '@/apis/ajax';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'SignIn',
    component: SignIn,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/common',
    name: 'Common',
    component: Common,
    meta: {
      keepAlive: false,
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
        meta: {
          title: '首页',
          keepAlive: false,
          menuId: "1",
          bigMenu: '首页'
        }
      },
      {
        path: 'manage',
        name: 'Manage',
        component: () => import(/* webpackChunkName: "about" */ '../views/manage/index.vue'),
        meta: {
          title: '管理员列表',
          keepAlive: false,
          menuId: "2-1",
          bigMenu: '管理员管理'
        }
      },
      {
        path: 'staff',
        name: 'Staff',
        component: () => import(/* webpackChunkName: "about" */ '../views/staff/index.vue'),
        meta: {
          title: '行政人员',
          keepAlive: false,
          menuId: "2-2",
          bigMenu: '管理员管理'
        }
      },
      {
        path: 'resume-list',
        name: 'ResumeList',
        component: () => import(/* webpackChunkName: "about" */ '../views/resume-list/index.vue'),
        meta: {
          title: '简历列表',
          keepAlive: false,
          menuId: "3-1",
          bigMenu: '简历管理'
        }
      },
      {
        path: 'publicity-list',
        name: 'PublicityList',
        component: () => import(/* webpackChunkName: "about" */ '../views/publicity-list/index.vue'),
        meta: {
          title: '宣传列表',
          keepAlive: false,
          menuId: "4-1",
          bigMenu: '宣传管理'
        }
      },
      {
        path: 'post-management',
        name: 'PostManagement',
        component: () => import(/* webpackChunkName: "about" */ '../views/post-management/index.vue'),
        meta: {
          title: '岗位列表',
          keepAlive: false,
          menuId: "5-1",
          bigMenu: '岗位管理'
        }
      },
      {
        path: 'job-classification',
        name: 'JobClassification',
        component: () => import(/* webpackChunkName: "about" */ '../views/job-classification/index.vue'),
        meta: {
          title: '岗位分类',
          keepAlive: false,
          menuId: "5-2",
          bigMenu: '岗位管理'
        }
      },
      {
        path: 'question-list',
        name: 'QuestionList',
        component: () => import(/* webpackChunkName: "about" */ '../views/question-list/index.vue'),
        meta: {
          title: '题型列表',
          keepAlive: false,
          menuId: "6-1",
          bigMenu: '题库管理'
        }
      },
      {
        path: 'question-bank-list',
        name: 'QuestionBankList',
        component: () => import(/* webpackChunkName: "about" */ '../views/question-bank-list/index.vue'),
        meta: {
          title: '题库列表',
          keepAlive: false,
          menuId: "6-2",
          bigMenu: '题库管理'
        }
      },
      // {
      //   path: 'score-level',
      //   name: 'ScoreLevel',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/score-level/index.vue'),
      //   meta: {
      //     title: '得分等级',
      //     keepAlive: false,
      //     menuId: "6-3",
      //     bigMenu: '题库管理'
      //   }
      // },
      {
        path: 'special-question-bank',
        name: 'SpecialQuestionBank',
        component: () => import(/* webpackChunkName: "about" */ '../views/special-question-bank/index.vue'),
        meta: {
          title: '特别题库',
          keepAlive: false,
          menuId: "6-4",
          bigMenu: '题库管理'
        }
      },
      {
        path: 'expand-question-bank',
        name: 'ExpandQuestionBank',
        component: () => import(/* webpackChunkName: "about" */ '../views/expand-question-bank/index.vue'),
        meta: {
          title: '拓展题库',
          keepAlive: false,
          menuId: "6-5",
          bigMenu: '题库管理'
        }
      },
      {
        path: 'interview-evaluation',
        name: 'InterviewEvaluation',
        component: () => import(/* webpackChunkName: "about" */ '../views/interview-evaluation/index.vue'),
        meta: {
          title: '评价列表',
          keepAlive: false,
          menuId: "7-1",
          bigMenu: '面试评价'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (getToken()) {
    return true;
  }
  if (to.name === 'SignIn') {
    return true;
  }
  router.push('/');
  return false;
})

export default router
