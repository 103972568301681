interface CrumbsState {
    crumbs: string;
}

const state: CrumbsState = { crumbs: '' };

const getters = {
    getCrumbs: (state: CrumbsState) => () => {
        return state.crumbs;
    }
}

const actions = {
    updateState(context: any, payload: string) {
        context.commit('updateState', payload);
    }
}

const mutations = {
    updateState(state: CrumbsState, payload: string) {
        state.crumbs = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}